import './TimeLine.css';
import Post from '../Post/Post';
import ChatRoomAccess from '../ChatBox/ChatRoomAccess';
import ChatRoom from '../ChatBox/ChatRoom'; 
import {pages, logo, loupeN, defaultPicture, chevron, newChat, chatBox} from '../assets/resources'
import ProfileAccess from '../ProfileAccess/ProfileAccess';
import { useEffect, useState } from 'react';
import {getRandomUsers, search,getProfilPicture} from '../Api/user.js'
import {createPost, getTimeLine, getTrends} from '../Api/message.js'
import {createChatRoom, getChatRooms } from '../Api/chat.js';
import { usePromiseTracker } from 'react-promise-tracker';

function TimeLinePage({changeCurrentPage,signOut,login}){

	const { promiseInProgress } = usePromiseTracker();

	const [randomUser, setRandomUser] = useState([]); 
	const [messagesList, setMessagesList] = useState([]); 
	const [refreshMessage, setRefreshMessage] = useState(false)
	const [searchValue, setSearchValue] = useState('')
	const [searchList, setSearchList] = useState(null)
	const [tendance, setTendance] = useState(null)
	const [ProfilePicture,setProfilePicture]=useState(defaultPicture)

	//Chat
	const [showChatBox, setShowChatBox] = useState(false)
	const [chatRooms, setChatRooms] = useState()
	const [showNewChatRoom, setShowNewChatRoom]=useState(false)
	const [chatRoom, setChatRoom] = useState()

 
	useEffect(() => {
        async function getRandom() {
            return await getRandomUsers();
        }
        
        getRandom().then((list) => {
        	setRandomUser(list);
        });
	},[login])

	useEffect(() => {
        async function getPP(){
            return await getProfilPicture(login)
        }

        getPP().then((pp) => {
            setProfilePicture(pp); 
        })

    }, []);

	useEffect(() => {
        async function getMessagesList(){
            return await getTimeLine()
        }

        getMessagesList().then((list) => {
            setMessagesList(list); 
        })

		async function getTendances(){
			return await getTrends()
		}

		getTendances().then((list)=> {
			if(list){
				setTendance(list.keywords)
			}else{
				setTendance(null)
			}
		})


    }, [login, refreshMessage]);

	useEffect(() => {
		async function searchlist(){
			if(searchValue!==""){
				const value = escapeRegex(searchValue) 
				return await search(value)
			}
		}

		searchlist().then((list)=> {
			if(list){
				setSearchList(list.data)
			}
			else{setSearchList(null)}
		})
	},[login, searchValue]); 

	useEffect(()=>{
		async function getChatRoom(){
			return await getChatRooms()
		}

		getChatRoom().then((list)=>{
			setChatRooms(list)
		})

	},[login, createChatRoom, showChatBox, chatRoom])

	function accessChatRoom(chatroom){
		setChatRoom(chatroom)
	}

	const escapeRegex = (str) => {
		return str.replace(/[.#*+?^${}()|[\]\\]/g, '');
	  }; 

	const handleSubmitSearchBar = (event) => {
		event.preventDefault()
		const value = document.getElementById('searchBar').value
		setSearchValue(value)
	}

	const handleSubmitNewChatRoom = async (event) => {
		event.preventDefault()
		const value = document.getElementById('newChatSearchBar').value
		const chatroom = await createChatRoom({login:value})
		accessChatRoom(chatroom)
	}

	const handleSubmitNewPost = (event) => {
		event.preventDefault()
		const textInput = document.getElementById('newPost').value
		createPost({content : textInput, branch : 'main'})
		document.getElementById('newPost').value = ""
		setRefreshMessage(!refreshMessage)
	}


    return(
	<div>
		<header className='TimeLine-header'>
			<button id="birdy-button" onClick={()=> searchList ? setSearchList(null) : null}>
				<img id= "icon" src={logo} alt="icon" />
				<div className='TimeLine-div-h1'>
					<h1 className="TimeLine-h1-good">good</h1>
					<h1 className="TimeLine-h1-news">news</h1>
				</div>
			</button>
			<form id="research" onSubmit={handleSubmitSearchBar}>
				<input type="search" id="searchBar" />
				<button type="submit" id="loupe-button"> <img src={loupeN} id="loupe" alt="loupe" /> </button>
			</form>
			<button id="headerProfileAccess" onClick={() => { changeCurrentPage( {page:pages.PROFILE,login:login})}}>
				<img id="header-pp" src={ProfilePicture} alt="Profile-picture" />
			</button>
		</header>
		<main className='TimeLine-main'>
			<aside className="TimeLine-aside" id='suggestion-aside'>
				<h3 className='TimeLine-h3'> Suggestions </h3>
				<ul>
                	{Array.isArray(randomUser) && randomUser.map((element) =>
                    	<li key={element.login}>
	                    	<ProfileAccess changeCurrentPage={changeCurrentPage} login={element.login} closeFollowList={null} />
                        </li>
                    )}
            	</ul>

				<h3 className='TimeLine-h3'> Tendance </h3>
				<div>
					{Array.isArray(tendance) && tendance.map((element) =>
						<li key={element}>
							<button id='tendance-button' onClick={()=>setSearchValue(element[0])}>
								<h4 id='tendance-h4'>{element[0]}</h4>
								<span>{element[1].post} posts dans les dernières 24h</span>
							</button>
						</li>
					)}
				</div>
			</aside>
			<aside className="TimeLine-aside" id="profile-timeline">
				<h3 className='TimeLine-h3'> Profil </h3>
				<div id='profile-access'>
					<ProfileAccess changeCurrentPage={changeCurrentPage} login={login} closeFollowList={null} />
				</div>
			</aside>
			{searchList && 
				<div id="listeCom" className="TimeLine-div" >
					<h3 className="TimeLine-h3">Résultats pour "{searchValue}" :</h3>
					<h4 >Personnes :</h4>
					{searchList.users && Array.isArray(searchList.users) && searchList.users.map((element) =>
						<li key={element.id}>
							<ProfileAccess changeCurrentPage={changeCurrentPage} login={element.login} closeFollowList={null} />
						</li>
					)}
					<h4>Publications :</h4>
					{searchList.messages && Array.isArray(searchList.messages) && searchList.messages.map((element) =>
						<li key={element.messageid}>
							<Post login={login} message={element} changeCurrentPage={changeCurrentPage}/>
						</li>
					)}
				</div>
			}
			{!searchList &&
				<div>
					<div id="post" className="TimeLine-div">
						<button id="NewPostProfileAccess" onClick={() => { changeCurrentPage( {page:pages.PROFILE,login:login})}}>
							<img id="newpost-pp" src={ProfilePicture} alt="Profile-picture" />
						</button>
						<textarea id="newPost" placeholder="Quelque chose à raconter ?" className='TimeLine-textarea' ></textarea>
						<button type="submit" id="timeline-post-button" className="TimeLine-button" onClick={(handleSubmitNewPost)} >Poster</button>
					</div>
					<div id="listeCom" className="TimeLine-div" >
						<h3 className="TimeLine-h3">Publications</h3>
						{Array.isArray(messagesList) && messagesList.map((element) =>
							<li key={element.messageid}>
								<Post login={login} message={element} changeCurrentPage={changeCurrentPage}/>
							</li>
						)}
					</div>	
				</div>		
			}
			<div className={'Chat-main'}> 
				{!chatRoom && <div className='Chat-div' >
					<h4 id="Chat-h4">Messages</h4>
					<button id="new-chat-button" onClick={() => { setShowNewChatRoom(!showNewChatRoom)}}> 
						<img id="new-chat-img" src={newChat} alt="new chat" />
					</button>
					<button id="open-chat-box-button-2" onClick={() => { setShowChatBox(!showChatBox); {(showChatBox && showNewChatRoom) && setShowNewChatRoom(!showNewChatRoom)}}}> 
						<img className={`chevron-open-chat-box ${(showChatBox) ? 'chevron-close-chat-box':'' }`} src={chevron} alt="open Chat box" />
					</button>
					{showNewChatRoom && <form id="newChatRoom" onSubmit={handleSubmitNewChatRoom}>
						<input type="search" id="newChatSearchBar" />
						<button type="submit" id="loupe-button"> <img src={loupeN} id="loupe" alt="loupe" /> </button>
					</form>	}
					{showChatBox && <div className='chatRooms'> 
						{Array.isArray(chatRooms) && chatRooms.map((element) =>
							<li key={element.chatRoomId}>
								{element.lastChat.chat.content && <ChatRoomAccess login={login} chatRoom={element} accessChatRoom={accessChatRoom}/>}
							</li>
						)}
					</div>}
				</div>}
				{chatRoom && <ChatRoom login={login} chatRoom={chatRoom} changeCurrentPage={changeCurrentPage} accessChatRoom={accessChatRoom}/>}
			</div>
			<div className="ChatBox-button">
				<button id="open-chat-box-button" onClick={() => { changeCurrentPage( {page:pages.CHATBOX}) }}> 
					<img id="chat-box-open" src={chatBox} alt="open Chat box" />
				</button>
			</div>
		</main>
	</div>
    )
}

export default TimeLinePage;