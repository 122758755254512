import { useState } from 'react';
import './SignUpPage.css';
import { pages, defaultPicture } from '../assets/resources';

function SignUpPage({ changeCurrentPage, signUp }) {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [errorLogin, setErrorLogin] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [error, setError]=useState('')

  const handleLoginChange = (event) => {
    setError('')
    const newLogin = event.target.value;
    setLogin(newLogin);
    if ( /^[a-zA-Z0-9]{1,20}$/.test(newLogin)) {
		setErrorLogin('');
    	event.target.classList.remove('error');
    	event.target.classList.add('valid');
    }
    else if(newLogin.length > 20){
      setErrorLogin('Le login doit contenir moins de 20 caractères');
      event.target.classList.remove('valid');
      event.target.classList.add('error');
    }
    else {
		  setErrorLogin('Le login ne doit pas contenir de caractères spéciaux');
    	event.target.classList.remove('valid');
    	event.target.classList.add('error');
    }
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    if (/(?=(.*\d){4})(?=.{10,})/.test(newPassword)) {
      setErrorPassword('');
      event.target.classList.remove('error');
      event.target.classList.add('valid');
    } else if(newPassword.length<10){
      setErrorPassword('Le mot de passe doit contenir au moins 10 caractères');
      event.target.classList.remove('valid');
      event.target.classList.add('error');
    }else{
		  setErrorPassword('Le mot de passe doit contenir au moins 4 chiffres');
      event.target.classList.remove('valid');
      event.target.classList.add('error');
	  }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (errorLogin || errorPassword) {
      return;
    }
    const first = document.getElementById('SignUp-first').value;
    const last = document.getElementById('SignUp-last').value;
    const response = await signUp({ login, password, first, last, ProfilePicture: defaultPicture });
    if(response.status===200){console.log("Utilisateur crée : ", response.data)}
    setError(response)
  };

  return (
    <div className="SignUp">
      <header className="SignUp-header">
        <h1 className="SignUp-h1">Enregistrement</h1>
      </header>
      <main className="SignUp-main">
        <form onSubmit={handleSubmit} className="SignUp-form">
          <input id="SignUp-last" placeholder="Nom" className="SignUp-input" />
          <input id="SignUp-first" placeholder="Prénom" className="SignUp-input" />
          <input id="SignUp-login" name="login" placeholder="Login"
            className={`SignUp-input ${errorLogin ? 'error' : ''} ${!errorLogin && login ? 'valid' : ''}`}
            value={login} onChange={handleLoginChange}
          />
          {error && error.status!==200 && <div className="SignUp-error-message">{error.data}</div>}
          {errorLogin && <div className="SignUp-error-message">{errorLogin}</div>}
          <input id="SignUp-password" name="password" type="password" placeholder="Mot de passe"
            className={`SignUp-input ${errorPassword ? 'error' : ''} ${!errorPassword && password ? 'valid' : ''}`}
            value={password} onChange={handlePasswordChange}
          />
          {errorPassword && <div className="SignUp-error-message">{errorPassword}</div>}
          <button type="submit" id="SignUp-button1" className={`SignUp-button ${(errorPassword || errorLogin) ? 'invalid' : ''} ${((!errorPassword && password)&&(!errorLogin && login)) ? 'valid' : ''}`}>
            Créer mon compte
          </button>
        </form>
        <div className="separateur"></div>
        <button onClick={() => changeCurrentPage({ page: pages.LOGIN })} id="SignUp-button2" className="SignUp-button">
          Annuler
        </button>
     

      </main>
    </div>
  );
}

export default SignUpPage;
