import axios from "axios";

const api = axios.create({
    baseURL:'https://julesherve.com:4001',
    timeout:15000,
    headers: {
        "Custom-Header":"header",
        "Content-Type":"application/json",
    },
    withCredentials:true
});

api.interceptors.response.use(undefined, function (error) {
    if (error.reponse && error.response.status === 502) {
        console.log("refresh")
      window.location.reload();
    }

    return Promise.reject(error);
});

export {api}
