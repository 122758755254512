import './ProfileModifier.css'
import './DeletePanel/DeletePanel.css'
import PasswordModifier from './PasswordModifier/PasswordModifier';
import ImportImage from './ImportImage/ImportImage';
import {cross,chevron, defaultPicture} from '../assets/resources';
import { useEffect, useState } from 'react';
import { deleteUser,changeLogin} from '../Api/user';


function ProfileModifier({closeProfileModifier,login,refreshPict, signOut,changeLoginState2}){

    const [MainVisible,setMainVisible]=useState(true)
    const [ImportImageVisible,setImportImageVisible]=useState(false)
    const [PasswordModifierVisible,setPasswordModifierVisible]=useState(false)
    const [DeletePanel,setDeletePanel]=useState(false)
    const [ProfilePicture,setProfilePicture]=useState(defaultPicture)
    const [newLogin, setLogin] = useState('');
    const [errorLogin, setErrorLogin] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {

        async function getPP(){
            return await refreshPict()
        }

        getPP().then((pp) => {
            setProfilePicture(pp); 
        })

    }, [ImportImageVisible]);


    function closeImageImport(){
        setImportImageVisible(false);
        setMainVisible(true)
    }

    function closePasswordModifier(){
        setPasswordModifierVisible(false);
        setMainVisible(true)
    }

    const handleLoginChange = (event) => {
        const newlogin = event.target.value;
        setLogin(newlogin);
        if ( /^[a-zA-Z0-9]{1,20}$/.test(newlogin)) {
            setErrorLogin('');
            event.target.classList.remove('error');
            event.target.classList.add('valid');
        }
        else if(newLogin.length > 20){
            setErrorLogin('Le login doit contenir moins de 20 caractères');
            event.target.classList.remove('valid');
            event.target.classList.add('error');
        }
        else {
            setErrorLogin('Le login ne doit pas contenir de caractères spéciaux');
            event.target.classList.remove('valid');
            event.target.classList.add('error');
        }
    };

    async function handleSubmitLogin(event){
        event.preventDefault();
        if (errorLogin || newLogin==="") {
            return;
        }
        const response=await changeLogin(newLogin);
        if(response.status===200){
            console.log("Login changé : ", response.data)
            setError(response)
            changeLoginState2(newLogin);
            console.log(response)
            setTimeout(() => { closeProfileModifier() }, 200);
            return
        }
        setError(response)
    }
    

    return (
        <div>
            {MainVisible &&
            <div id='ProfileModifier'>
                <button onClick={() => { closeProfileModifier() }} id='ProfileModifier_exitButton'>
                        <img id='ProfileModifier_cross' src={cross}></img>
                </button>
                <button type='button' id='ProfileModifier_pp_button' onClick={() => { setMainVisible(false);setImportImageVisible(true) }}>
                    <img src={ProfilePicture} id='ProfileModifier_pp_image' ></img>
                    <span id='ProfileModifier_pp_text'>Changer de photo de profil</span>
                </button>
                <form  id='ProfileModifier_form' onSubmit={handleSubmitLogin}>
                    <span id='ProfileModifier_form_text'>Modifier votre login</span>

                    <input id='ProfileModifier_login'  placeholder={login}
                        className={`SignUp-input ${errorLogin ? 'error' : ''} ${!errorLogin && login ? 'valid' : ''}`}
                        onChange={handleLoginChange}
                    />
                    {errorLogin && <div className="SignUp-error-message">{errorLogin}</div>}
                    {error && error.status!==200 && <div className="SignUp-error-message">{error.data}</div>}
                    <button type="submit" className={`ProfileModifier_button ${(errorLogin) ? 'invalid' : 'valid'}`}> confirmer </button>
                </form>
                <div className="separateur"></div>
                <button id='passwordModifierButton' onClick={()=>{setMainVisible(false);setPasswordModifierVisible(true)}}>
                    <span>Modifier votre mot de passe</span>
                    <img src={chevron} id='passwordModifier_img'></img>
                </button>
                <div className="separateur"></div>
                <button id='ProfileModifier_sup_button' className='ProfileModifier_button' onClick={()=>{setDeletePanel(true);setMainVisible(false)}}>suppprimer le compte</button>
            </div>
            }
            {ImportImageVisible && <ImportImage closeImageImport={closeImageImport}/>}
            {PasswordModifierVisible && <PasswordModifier closePasswordModifier={closePasswordModifier}/>}
            {DeletePanel && 
                <div className='Delete-main'>
                    <div className='Delete-header'>
                        <button className='Delete_exitButton' onClick={() =>{setDeletePanel(false);setMainVisible(true)}}>
                            <img src={chevron} className='Delete_exit_cross'></img>
                        </button>
                        <h2 className='Delete-h2'>Êtes-vous sûr de vouloir suppprimer votre compte ?</h2>
                    </div>
                        
                    <div className='Delete-div'>
                        <button className='ProfileModifier_button' id='Cancel-Button' onClick={() =>{setDeletePanel(false);setMainVisible(true)}}>Annuler</button>
                        <button className='ProfileModifier_button' id='Supp-Button' onClick={()=>{deleteUser();signOut();}}>Supprimer</button>
                    </div>
                </div>
                }
        </div>
    );

}

export default ProfileModifier