import './ProfileAccess.css'
import {pages,defaultPicture} from '../assets/resources';
import { useEffect,useState } from 'react';
import { getProfilPicture } from '../Api/user';

function ProfileAccess({login,changeCurrentPage, closeFollowList}){

    const [ProfilePicture,setProfilePicture]=useState(defaultPicture)

    useEffect(()=>{
        async function getPP(){
            return await getProfilPicture(login);
        } 
        getPP().then((pp) => {
            if(pp){
                setProfilePicture(pp)
            }
        })
    },[]);
    

    return (
        <button onClick={() => { changeCurrentPage( {page:pages.PROFILE,login:login}); closeFollowList && closeFollowList()}} id="ProfileAccess_main">
            <img src={ProfilePicture} id="ProfileAccess_picture" alt="Profile-picture" />
            <b>{login}</b>
        </button>
    );
} 
export default ProfileAccess