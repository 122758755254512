import './Publication.css'; 
import Post from './Post';
import FollowList from '../ProfilePage/FollowList';
import {pages, heart_like,heart_unlike,com, defaultPicture, chevron, trash} from '../assets/resources';
import {createPost, deletePost, like, isLike, getMessage, getListMessages} from '../Api/message.js'; 
import { useEffect, useState } from 'react';
import { getProfilPicture } from '../Api/user';

function Publication({changeCurrentPage, message, login, isSuperUser}){
    
    const [messageState, setMessage] = useState(message); 
    const [isLikeState, setIsLike] = useState((message.like.indexOf(login)===-1 ? false : true))
    const [nbLike, setNbLike ] = useState(message.like.length)
    const [likeButton, setLikeButton] = useState(0)
    const [refreshMessage, setRefreshMessage] = useState(false)
    const [commentArea, setCommentArea] = useState(false)
    const [commentList, setCommentList] = useState([]); 
    const [nbCom, setNbCom ] = useState(commentList.length)
    const [enRepA, setEnRepA]= useState()
    const [ProfilePicture,setProfilePicture]=useState(defaultPicture);
    const [showLike,setShowLike]=useState("hide");

    function closeLikeList(){
        setShowLike("hide");
    }

    useEffect(()=>{
        setMessage(message)
    },[message]);

    useEffect(()=>{
        async function getPP(){
            return await getProfilPicture(message.login);
        } 
        getPP().then((pp) => {
            if(pp){
                setProfilePicture(pp)
            }
        })
    },[message]);

    useEffect(() => {
        async function messageGet(){
            return await getMessage({messageid:message.messageid, branch:null})
        }

        messageGet().then((list) => {
            setMessage(list);
        })

        async function getCommentList(){
            const branch = message.messageid
            return await getListMessages({login : null,branch: branch})
        }

        getCommentList().then((list) => {
            setCommentList(list); 
            setNbCom(list.length)
        })

    }, [login, refreshMessage, message]);

    
    useEffect(() => {
        async function getEnRepA(){
            if(message.branch !== 'main'){
                return await getMessage({messageid:null, branch: message.branch})
            }
        }
        getEnRepA().then((list) => {
            if(list){
                console.log("Publi : ", message.content, "en rep à : ", list.content)
            }
            setEnRepA(list)
        })
    }, [message])


    useEffect(() => {
        async function getIsLike(){
            return await isLike(message.messageid)
        }

        getIsLike().then((list) => {
            setIsLike(list.data.isLike);
            setNbLike(list.data.nbLike)
        })
    }, [login, messageState.like]);

    useEffect(() => {
        async function liker(){
            if(likeButton!=0){
                return await like(messageState.messageid)
            }
            return; 
        } 
        liker().then((list) => {
            setRefreshMessage(!refreshMessage)
        })
    
    }, [likeButton]); 

    



    const handleSubmitNewCom = (event) => {
		event.preventDefault()
		const textInput = document.getElementById('newCom').value
		createPost({content : textInput, branch : message.messageid})
		document.getElementById('newCom').value = ""
        setRefreshMessage(!refreshMessage)
        setCommentArea(!commentArea)
	}



    return (
        <div id='post-main' >
            <button onClick={() => { changeCurrentPage({page:pages.TIMELINE}) }} id="Publication-return">
                <img src={chevron}></img>
            </button>
            <header id="post-header">
                <button onClick={() => { changeCurrentPage({page:pages.PROFILE, login : message.login}) }} id="Profile-return">
                    <img src={ProfilePicture} id="publication-picture" alt="Profile-picture" />
                </button>
                {message.branch!=='main' && enRepA && <button className='enreponse' onClick={() => { changeCurrentPage({page:pages.PUBLICATION, message : enRepA})}}>en réponse à @{enRepA.login}</button>}
                <div className='post'>
                    <h3 className='login-publi'>@{message.login}</h3>
                    <span className='content-publi'>“ {message.content} ”</span>
                </div>
                {isSuperUser && <button id="delete-post-button" onClick={()=>{deletePost(message.messageid); changeCurrentPage({page:pages.TIMELINE})}}>
                    <img src={trash} id="trash" alt="Delete publication" />
                </button>}
            </header>
            <div id='post-button'>
                <div className='like-div'>
                    <button className='like' id="open-likeList" onClick={() => { setShowLike("like") }}>{nbLike}</button>
                    <button className='like' id="like-button" onClick={() => {setLikeButton(likeButton-1)}}>
                        {!isLikeState && <img id="heart" src={heart_unlike}></img>}
                        {isLikeState && <img id="heart" src={heart_like}></img>}
                    </button>
                </div>
                <button className='like' onClick={() => {setCommentArea(!commentArea)}}> 
                    {nbCom}
                    <img id="heart" src={com}></img>
                </button>
                <span className='date'>{messageState.dateFormat}</span>
                {commentArea &&<div id='divCom'>
                    <textarea id="newCom" placeholder={"Commentez en tant que @"+login} className='Publication-textarea' ></textarea>
		            <button type="submit" id="publication-post-button" className="Publication-button" onClick={(handleSubmitNewCom)} >Poster</button>
                </div>}
            </div>
            <div id='listCom'>
                {Array.isArray(commentList) && commentList.map((element) =>
                    <li id ='liCom' key={element.messageid}>
            		    <Post login={login} message={element} changeCurrentPage={changeCurrentPage}/>
    	    	    </li>
                )}
            </div>
            {showLike !=="hide" && <div className='blur'></div>}
            {showLike === "like" && <FollowList closeFollowList = {closeLikeList} changeCurrentPage = {changeCurrentPage} status={"Like"} followlist = {messageState.like}/>}
        </div>
    );
} 
export default Publication