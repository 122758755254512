import './LoginPage.css';
import {pages} from '../assets/resources';
import { useState } from 'react';

function LoginPage({ changeCurrentPage, signIn}) {
    const [error, setError] = useState()

    const handleSubmit = async (event) => {
      event.preventDefault(); 
      const login = document.getElementById('login').value;
      const password = document.getElementById('password').value;
      const response = await signIn({ login, password })
      setError(response);
    };
  
    return (
      <div>
        <header className="login_header">
          <h1 className="login_h1">Connexion</h1>
        </header>
  
        <main className="login_main">
          <form onSubmit={handleSubmit} className="login_form">
            <input id="login" name="login" placeholder="Login" className="login_input" />
            <input id="password" name="password" type="password" placeholder="Mot de passe" className="login_input" />
            {error && error.status!==200 && <div className="SignUp-error-message">{error.data}</div>}
            <button type="submit" id="button1" className="login_button">Se connecter</button>
          </form>
          <div className="separateur" id="separateur1"></div>
          <button className="login_button" id="button2" onClick={() => { changeCurrentPage({page:pages.SIGNUP}) }}>Créer un compte</button>
        </main>
      </div>
    );
  }
  

export default LoginPage;