import {createChatRoom, getChatRooms } from '../Api/chat.js';
import {getProfilPicture} from '../Api/user.js'
import {pages, loupeN, defaultPicture, chevron, newChat, chatBox} from '../assets/resources'
import { useEffect, useState } from 'react';
import ChatRoomAccess from './ChatRoomAccess';
import './ChatBox.css'

function ChatBox({changeCurrentPage, login}){

    const [ProfilePicture,setProfilePicture]=useState(defaultPicture)
	const [chatRooms, setChatRooms] = useState()

    useEffect(() => {
        async function getPP(){
            return await getProfilPicture(login)
        }

        getPP().then((pp) => {
            setProfilePicture(pp); 
        })

    }, []);

    useEffect(()=>{
		async function getChatRoom(){
			return await getChatRooms()
		}

		getChatRoom().then((list)=>{
			setChatRooms(list)
		})

	},[login, createChatRoom])

	function accessChatRoom(chatroom){
		changeCurrentPage({page : pages.CHATROOM , chatRoom : chatroom})
	}

    const handleSubmitNewChatRoom = async (event) => {
		event.preventDefault()
		const value = document.getElementById('newChatSearchBar').value
		const chatroom = await createChatRoom({login:value})
		accessChatRoom(chatroom)
	}

    return(
        <div className={'ChatBox-main'}> 
				<div className='ChatBox-div' >
					<div className='ChatBox-header' >
						<button onClick={() => { changeCurrentPage({page:pages.TIMELINE}) }} id="ChatBox-return-Timeline">
							<img src={chevron} id="ChatBox-chevron"></img>
						</button>
						<h4 id="ChatBox-h4">Messages</h4>
					</div>
					<form id="newChatRoom" onSubmit={handleSubmitNewChatRoom}>
						<input type="search" id="newChatSearchBar" />
						<button type="submit" id="loupe-button"> <img src={loupeN} id="loupe" alt="loupe" /> </button>
					</form>
					<div className='chatRooms'> 
						{Array.isArray(chatRooms) && chatRooms.map((element) =>
							<li key={element.chatRoomId}>
								{element.lastChat.chat.content && <ChatRoomAccess login={login} chatRoom={element} accessChatRoom={accessChatRoom}/>}
							</li>
						)}
					</div>
			    </div>
			</div>
    )


}

export default ChatBox;