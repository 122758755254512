import { useState,useEffect } from 'react';
import LoginPage from './Login/LoginPage';
import SignUpPage from './SignUp/SignUpPage';
import TimeLinePage from './TimeLine/TimeLine';
import ProfilePage from './ProfilePage/ProfilePage';
import Publication from './Post/Publication'
import ChatBox from './ChatBox/ChatBox'
import ChatRoom from './ChatBox/ChatRoom'
import {pages} from './assets/resources'
import {api} from './Api/api'
import {createUser,getFollowerList, getFollowingList, deconnexion, sendLogin, newFriend, supFriend} from './Api/user';


function App() {

  //------------USE STATE----------
  const [connected, setConnected]=useState(false)
  const [login,setLogin]=useState("Non defini")
  const [currentPage,setCurrentPage]=useState({page:pages.LOGIN})
  const [id,setId]=useState("")
  const [followingList, setFollowingList]=useState([])
  const [followerList, setFollowerList]=useState([])

  //------------FUNCTION-----------
  const getFirstPage= async () => {
    try {
        const response= await api.get('/user/profile');
        if (response.data){
          const {login,id}=response.data
          setConnected(true)
          setLogin(login)
          setId(id)
        }
        else {
          setConnected(false)
        }
    }
    catch (error) {
        console.error(error);
        return ""
    }
  }
  
  useEffect(() => {
    getFirstPage();
  }, []);
  
  const changeStatusConnection = () => {
    setConnected(!connected);
  }

  useEffect(() => {
    setCurrentPage(connected ? {page:pages.TIMELINE} : {page:pages.LOGIN});
  }, [connected]);

  const changeCurentPage = (newPage) => {
    setCurrentPage(newPage);
  }

  //------------USER---------------
  const signIn = async (formData) => {
    const response= await sendLogin(formData)
    if(response.status===200){
      const {login,password}=formData
      setLogin(login)
      setId(response);
      changeStatusConnection();
    }
    return response
  };


  const signUp = async (formData) => {

    const response = await createUser(formData);
    const{login,pasword,first,last, ProfilePicture}=formData
    if (response.data && response.status ===200){
      setLogin(login)
      setId(response.data)
      changeStatusConnection();
    }
    return response
  }

  function signOut(){
    setConnected(false);
    setTimeout(() => { deconnexion(); }, 200);
  }

  const changeLoginState = (newLogin)=>{
    setLogin(newLogin);
  }

  useEffect(() => {
    const fetchFollowList = async () => {
      if (connected) {
        const followingList = await getFollowingList(login);
        setFollowingList(followingList);

        const followerList = await getFollowerList(login);
        setFollowerList(followerList);
      }
    };

    fetchFollowList();
  }, [login]);


  const addFriend = async (formData) => {
    
    const response = await newFriend(formData)

    const followingList= await getFollowingList(formData.login)
    setFollowingList(followingList)

    const followerList = await getFollowerList(formData.login)
    setFollowerList(followerList)

    return response
  }

  const deleteFriend = async (formData) => {
    const response = await supFriend(formData.login)

    const followingList= await getFollowingList(formData.login)
    setFollowingList(followingList)

    const followerList = await getFollowerList(formData.login)
    setFollowerList(followerList)

    return response
  }

  //------------RENDER-------------



  return (
      <div>
      {currentPage.page===pages.LOGIN && <LoginPage changeCurrentPage={changeCurentPage} signIn={signIn}/>}
      {currentPage.page===pages.SIGNUP && <SignUpPage changeCurrentPage={changeCurentPage} signUp={signUp}/>}
      {currentPage.page===pages.TIMELINE && <TimeLinePage changeCurrentPage={changeCurentPage} signOut={signOut} login={login} />}
      {currentPage.page===pages.PROFILE && <ProfilePage changeCurrentPage={changeCurentPage} loginInput={currentPage.login} isSuperUser={login===currentPage.login?true:false} addFriend={addFriend} deleteFriend={deleteFriend} signOut={signOut} changeLoginState={changeLoginState}/>}
      {currentPage.page===pages.PUBLICATION && <Publication changeCurrentPage={changeCurentPage} message={currentPage.message} login={login} isSuperUser={login===currentPage.message.login?true:false}/>}
      {currentPage.page===pages.CHATBOX && <ChatBox changeCurrentPage={changeCurentPage} login={login}/>}
      {currentPage.page===pages.CHATROOM && <ChatRoom changeCurrentPage={changeCurentPage} login={login} chatRoom={currentPage.chatRoom} accessChatRoom={null} />}
    </div>
  )
  }


export default App;