// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../var.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#ProfileAccess_main{\n    display:flex;\n    margin:5px 0%; \n    padding :3px;\n    align-items:center;\n    width: -webkit-fill-available;\n}\n\n#ProfileAccess_picture{\n    width:20%;\n    border-radius: 100%;\n    margin : 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/ProfileAccess/ProfileAccess.css"],"names":[],"mappings":"AAEA;IACI,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,SAAS;IACT,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":["@import '../var.css';\n\n#ProfileAccess_main{\n    display:flex;\n    margin:5px 0%; \n    padding :3px;\n    align-items:center;\n    width: -webkit-fill-available;\n}\n\n#ProfileAccess_picture{\n    width:20%;\n    border-radius: 100%;\n    margin : 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
