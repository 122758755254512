import React, { useState } from 'react';
import './ImportImage.css'
import {putProfilPicture} from '../../Api/user.js'
import {chevron} from '../../assets/resources'



function ImportImage({closeImageImport}) {
  const [image, setImage] = useState(null);

  function handleImageUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = function (event) {
      const image = new Image();
      image.onload = function() {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const size = Math.min(image.width, image.height);
        const outPutSize=400
  
        canvas.width = outPutSize;
        canvas.height = outPutSize;
  
        const x = image.width < size ? 0 : (image.width - size) / 2;
        const y = image.height < size ? 0 : (image.height - size) / 2;
  
        context.drawImage(image, x, y, size, size, 0, 0, outPutSize, outPutSize);
  
        const dataUrl = canvas.toDataURL(file.type);
        setImage(dataUrl);
      };
      image.src = event.target.result;
    };
  
    reader.readAsDataURL(file);
  }
  
  


  async function handleSubmit(event){
    event.preventDefault();
    const responses=await putProfilPicture(image);
    console.log(responses);
    setTimeout(() => { closeImageImport(); }, 200);
  }
  

  return (
    <div className='ImportImage_main'>
      <div className='ImportImage-header'>
        <button className='ImportImage_exitButton' onClick={() =>closeImageImport()}>
          <img src={chevron} className='ImportImage_exit_cross'></img>
        </button>
        <h2 className='ImportImage-h2'>Importer une image</h2>
      </div>
      <form className='ImportImage_form' onSubmit={handleSubmit}>
        <input type="file" onChange={handleImageUpload} />
        {image && <div className='separateur' id="ImportImage_separateur"></div>}
        {image && <img id='image-source' src={image} />}
        {image && <button className='ImportImage_button' type='submit' id='ImportImage'>valider</button>}
      </form>
    </div>
  );
}

export default ImportImage;
