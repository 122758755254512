import {api} from './api'

//------------MESSAGE------------
const createPost = async (formData) => {
  try {
    const response = await api.put('/message/post', formData);
    return response;
  } catch (error) {
    console.log("probleme dans App/createPost")
    console.error(error);
  }

}

const deletePost = async (formData) => {
  console.log(formData)
  try {
    const response = await api.delete('/message/delete/'+formData);
    return response;
  } catch (error) {
    console.log("probleme dans App/deletePost")
    console.error(error);
  }

}


const getListMessages = async (formData) => {
  try{
    const response = await api.get('/message/getListMessages/'+ formData.login +'/'+formData.branch)
    return response.data
  }catch(error){ 
    console.error(error)
    return false;
  }
}

const getMessage = async (formData) => {
  try{
    const response = await api.get('/message/getMessage/'+ formData.messageid +'/'+formData.branch)
    return response.data
  }catch(error){ 
    console.error(error)
    return false;
  }
}

const like = async (formData) => {
  try {
    const response = await api.put('/message/like/'+ formData);
    return response;
  } catch (error) {
    console.log("probleme dans App/like")
    console.error(error);
  }
}

const isLike = async (formData) => {
  try {
    const response = await api.get('/message/isLike/'+ formData);
    return response;
  } catch (error) {
    console.log("probleme dans App/isLike")
    console.error(error);
  }
}

const getTimeLine = async () => {
  try{
    const response = await api.get('/message/getTimeline/')
    return response.data
  }catch(error){ 
    console.error(error)
    return false;
  }
}

const getTrends = async () => {
  try{
    const response = await api.get('/trends')
    return response.data
  }catch(error){ 
    console.error(error)
    return false;
  }
}

export {createPost, deletePost, getListMessages,getMessage,like,isLike,getTimeLine, getTrends}