// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../var.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#post-main{\n    background-color: var(--color-lightgrey);\n    border-radius: 5px;\n    padding : 1.5%;\n    padding-bottom: 0;\n    margin: 5px 0%;\n}\n\n\n#post-header{\n    display: grid;\n    border : none;\n    border-radius: 5px;\n    text-align: left;\n    width: -webkit-fill-available;\n    background : none;\n}\n\n\n#post-picture{\n    width:10%;\n    border-radius: 100%;\n    grid-column: 1/3 ;\n\tgrid-row :1;\n}\n\n#publi-button{\n    text-align: left;\n    line-height: normal;\n}\n\n.login{\n    margin : 1% 12%;\n}\n\n.content{\n    margin : 0% 1% 1% 15%;\n}\n\n#post-button{\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n}\n", "",{"version":3,"sources":["webpack://./src/Post/Post.css"],"names":[],"mappings":"AAEA;IACI,wCAAwC;IACxC,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,cAAc;AAClB;;;AAGA;IACI,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,6BAA6B;IAC7B,iBAAiB;AACrB;;;AAGA;IACI,SAAS;IACT,mBAAmB;IACnB,iBAAiB;CACpB,WAAW;AACZ;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,qCAAqC;AACzC","sourcesContent":["@import '../var.css';\n\n#post-main{\n    background-color: var(--color-lightgrey);\n    border-radius: 5px;\n    padding : 1.5%;\n    padding-bottom: 0;\n    margin: 5px 0%;\n}\n\n\n#post-header{\n    display: grid;\n    border : none;\n    border-radius: 5px;\n    text-align: left;\n    width: -webkit-fill-available;\n    background : none;\n}\n\n\n#post-picture{\n    width:10%;\n    border-radius: 100%;\n    grid-column: 1/3 ;\n\tgrid-row :1;\n}\n\n#publi-button{\n    text-align: left;\n    line-height: normal;\n}\n\n.login{\n    margin : 1% 12%;\n}\n\n.content{\n    margin : 0% 1% 1% 15%;\n}\n\n#post-button{\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
