import logo from './icon.png';
import loupe from './loupe.svg';
import loupeN from './loupeN.svg';
import chevron from './chevron.svg';
import cross from './cross.svg';
import heart_unlike from './heart_unlike.svg'
import heart_like from './heart_like.svg'
import defaultPicture from './defaultPicture.png'
import deconnexion from './deconnexion.svg'
import com from './com.svg'
import trash from './delete.svg'
import newChat from './newChat.svg'
import send from './send.svg'
import chatBox from './chatBox.svg'
import { getProfilPicture } from '../Api/user';

const pages ={
    LOGIN:0,
    SIGNUP:1,
    TIMELINE:2,
    PROFILE:3,
    CHATBOX:4,
    CHATROOM : 5,
    FRIENDLIST:6,
    PUBLICATION : 7
};


export {pages, logo, loupe, loupeN, chevron,cross,heart_like,heart_unlike,deconnexion, com, trash, newChat, send, chatBox,defaultPicture}
