import './ProfilePage.css';
import ProfileModifier from './ProfileModifier'
import FollowList from './FollowList'
import Post from '../Post/Post';
import {pages, chevron,defaultPicture} from '../assets/resources';
import { useEffect, useState } from 'react';
import {getFollowingList, getFollowerList, isFriend,getProfilPicture} from '../Api/user.js';
import { getListMessages } from '../Api/message.js';


function ProfilePage({changeCurrentPage,loginInput, isSuperUser, addFriend, deleteFriend, signOut,changeLoginState}) {

    const [followingList, setFollowingList] = useState([]);
    const [followerList, setFollowerList] = useState([]);
    const [messagesList, setMessagesList] = useState([]); 
    const [isfriend, setisfriend] = useState([]); 
    const [showModifier,setShowModifier]=useState(false);
    const [showFollow,setShowFollow]=useState("hide");
    const [ProfilePicture,setProfilePicture]=useState(defaultPicture)
    const [followButton, setFollowButton] = useState(FollowButton())

    const nb_post = Object.keys(messagesList).length
    const nb_followers = followerList.length
    const nb_following = followingList.length

    
    useEffect(() => {
        async function getListFollowing() {
            return await getFollowingList(loginInput);
        }
        
        getListFollowing().then((list) => {
          setFollowingList(list);
        });

        async function getListFollower(){
            return await getFollowerList(loginInput);
        }
        
        getListFollower().then((list) => {
          setFollowerList(list);
        });

        async function isFriend2() {
            const friendlogin=loginInput
            return await isFriend({login : friendlogin})
        }

        isFriend2().then((list)=>{
            setisfriend(list)
        });


    }, [getFollowingList, getFollowerList, loginInput, addFriend, deleteFriend]);

    useEffect(() => {
        async function getMessagesList(){
            return await getListMessages({login : loginInput,branch : null})
        }

        getMessagesList().then((list) => {
            setMessagesList(list); 
        })

    }, [getListMessages, loginInput, ProfilePicture]);

    useEffect(() => {
        async function getPP(){
            return await getProfilPicture(loginInput)
        }

        getPP().then((pp) => {
            setProfilePicture(pp); 
        })

    }, [showModifier,loginInput]);

   
    function closeProfileModifier(){
        setShowModifier(false);
    }

    function closeFollowList(){
        setShowFollow("hide");
    }

    async function refreshPict (){
        const pp=await getProfilPicture(loginInput);
        setProfilePicture(pp);
        return pp;
    }

    useEffect(() => {
        setFollowButton(FollowButton())
    }, [isfriend, loginInput]);

    function FollowButton(){
        if(isSuperUser){
            return <button className = 'follow-button' onClick={() => { setShowModifier(true) }} id="Profile-button">modifier le profil</button>
        }
        else{
            if(!isfriend){
               return <button className = 'follow-button' onClick={() => { addFriend({login:loginInput}) }} id="Profile-button"> follow </button>
            }
            else{return <button className = 'follow-button' onClick={() => { deleteFriend({login:loginInput}) }} id="Profile-button"> unfollow </button>}
            
        }
    }

    function changeLoginState2(newLogin){
        changeLoginState(newLogin);
        changeCurrentPage({page:pages.PROFILE,login:newLogin})
    }
   
        

    return(
        <div className = 'ProfilePage'>
            <header id='Profile-header' className = 'Profile-header'> 
                <img src={ProfilePicture} id="Profile-picture" alt="Profile-picture" />
                <h2 className = 'Profile-h1'>{loginInput}</h2>
                <button onClick={() => { changeCurrentPage({page:pages.TIMELINE}) }} id="ProfilePage-return">
                    <img src={chevron}></img>
                </button>
                <div id ='Profile_post' className='list-button'>
                    <b className='profile-number'>{nb_post}</b>
                    <span id='Publication-span'> Publications</span>
                </div>
                <button className='list-button' id='Profile_followers' onClick={() => {setShowFollow("follower")}}>
                    <b className='profile-number'>{nb_followers}</b>
                    <span>Followers</span>
                </button>
                <button className='list-button' id='Profile_following' onClick={() => {setShowFollow("following")}}>
                    <b className='profile-number'>{nb_following}</b>
                    <span>Following</span>
                </button>
                {isSuperUser && <button className='deconnexion-button' onClick={()=>{signOut();}}>deconnexion</button>}
                {followButton}
            </header>
            <main id="Profile-main" className="Profile-main">
                <main className='profile-messages'>
                    <div className='profile-messages-div' id="listepost" >
				        <h3 className="profile-h3">Publications</h3>
                            {Array.isArray(messagesList) && messagesList.map((element) =>
                                <li key={element.messageid}>
                                    <Post login={loginInput} message={element} changeCurrentPage={changeCurrentPage}/>
                                </li>
                            )}
			        </div>
                </main>
            </main>
            {(showModifier || showFollow !=="hide") && <div className='blur'></div>}
            {showModifier && <ProfileModifier closeProfileModifier={closeProfileModifier} login={loginInput} refreshPict={refreshPict} signOut={signOut} changeLoginState2={changeLoginState2}/>}
            {showFollow === "following" && <FollowList closeFollowList = {closeFollowList} changeCurrentPage = {changeCurrentPage} status={"Following"} followlist = {followingList}/>}
            {showFollow === "follower" && <FollowList closeFollowList = {closeFollowList} changeCurrentPage = {changeCurrentPage} status={"Followers"} followlist = {followerList}/>}
        </div>
    )
}

export default ProfilePage;