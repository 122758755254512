import './FollowList.css'
import ProfileAccess from '../ProfileAccess/ProfileAccess';
import {cross} from '../assets/resources';

function FollowList({closeFollowList, changeCurrentPage, status, followlist}){
    return ( 
        <div className='followList' id="follow">
            <header id="follow-header">
                <button onClick={() => { closeFollowList() }} id='follow_exitButton'>
                    <img id='exit_cross' src={cross}></img>
                </button>
                <h2 id="follow-h2">{status}</h2>
            </header>
            <ul>
                {Array.isArray(followlist) && followlist.map((element) =>
                    <li key={element}>
                        <ProfileAccess changeCurrentPage={changeCurrentPage} login={element} closeFollowList={closeFollowList} />
                    </li>
                )}
            </ul>
        </div>
    )
}

export default FollowList
