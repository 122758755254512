import './ChatRoom.css'
import {pages,defaultPicture, chevron, send} from '../assets/resources';
import { useEffect,useState } from 'react';
import { getProfilPicture } from '../Api/user';
import {createChat, getChats} from '../Api/chat.js';

function ChatRoom({login,chatRoom, changeCurrentPage, accessChatRoom}){

    const [ProfilePicture,setProfilePicture]=useState(defaultPicture)
    const [friendLogin, setFriendLogin]=useState(chatRoom.login1 === login ? chatRoom.login2 : chatRoom.login1 )
    const [chats, setChats] = useState()

    useEffect(()=>{
        async function getPP(){
            return await getProfilPicture(friendLogin);
        } 
        getPP().then((pp) => {
            if(pp){
                setProfilePicture(pp)
            }
        })
    },[]);

    useEffect(() => {
        async function getChatsList() {
          return await getChats({chatRoomId: chatRoom.chatRoomId});
        }
      
        function updateChats() {
          getChatsList().then((list) => {
            if(list){
              setChats(list);
            }
          });
        }
      
        updateChats();
      
        const intervalId = setInterval(() => {
          updateChats();
        }, 1000);
      
        return () => clearInterval(intervalId);
    }, []);

    const handleSubmitNewChat = async (event) => {
		event.preventDefault()
		const value = document.getElementById('ChatRoom-newChat').value
        if(value===""){
            return; 
        }
		const chat = await createChat({chatRoomId: chatRoom.chatRoomId, content : value })
        document.getElementById('ChatRoom-newChat').value = ""
	}

    useEffect(() => {
        const chatRoomDiv = document.getElementById('ChatRoom-Chats');
        chatRoomDiv.scrollTo(0, chatRoomDiv.scrollHeight);
    }, [chats]);

    return (
        <div className='ChatRoom-div'>
            <header id="ChatRoom-header">
                <button onClick={() => { accessChatRoom ? accessChatRoom(null) : changeCurrentPage({page:pages.CHATBOX}) }} id="ChatRoom-quit">
                    <img src={chevron} id="ChatRoom-chevron" alt="Quit Chat Room" />
                </button>
                <button onClick={() => { changeCurrentPage( {page:pages.PROFILE,login:friendLogin}) }} id="ChatRoom-profile">
                    <img src={ProfilePicture} id="ChatRoom-picture" alt="Profile-picture" />
                </button>
                <h4 id="ChatRoom-h4">{friendLogin}</h4>
            </header>
            <div id = "ChatRoom-Chats">
                {Array.isArray(chats) && chats.map((element) =>
					<li id={element.login===login ? "ChatRoom-right" : "ChatRoom-left" } key={element.dateFormat}>
                        <div id={element.login===login ? "ChatRoom-Chat-right" : "ChatRoom-Chat-left"} >{element.content}</div>
					</li>
				)}
            </div>
            <div id="ChatRoom-newChat-div">
                <textarea id="ChatRoom-newChat" placeholder="Envoyer un Chat" className='TimeLine-textarea' ></textarea>
                <button type="submit" id="ChatRoom-newChat-button" onClick={handleSubmitNewChat} >
                    <img src={send} id="ChatRoom-send" alt="send Chat" />
                </button>
            </div>
        </div>
    );
} 
export default ChatRoom