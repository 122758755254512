import {api} from './api'

const createChatRoom = async (formData) => {
    try {
      const response = await api.put('/chatRoom/create', formData);
      return response.data;
    } catch (error) {
      console.log("problème dans App/createChatRoom")
      console.error(error);
    }
}

const getChatRooms = async () => {
    try{
        const response = await api.get('/chatRoom/getChatRooms')
        return response.data
    }catch(error){ 
        console.log("problème dans App/getChatRooms")
        console.error(error)
  }
}

const getChats = async (formData) => {
    console.log("getChats: ", formData)
    try{
        const response = await api.get('/chat/getChats/'+ formData.chatRoomId)
        return response.data
    }catch(error){ 
        console.log("problème dans App/getChats")
        console.error(error)
  }
}

const createChat = async (formData) => {
    console.log("createChat: ", formData)
    try {
      const response = await api.put('/chat/create', formData);
      return response;
    } catch (error) {
      console.log("problème dans App/createChat")
      console.error(error);
    }
}

export {createChatRoom, createChat, getChatRooms, getChats}