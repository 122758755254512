import './ChatRoomAccess.css'
import {pages,defaultPicture} from '../assets/resources';
import { useEffect,useState } from 'react';
import { getProfilPicture } from '../Api/user';
import { getChatRooms } from '../Api/chat';

function ChatRoomAccess({login,chatRoom, accessChatRoom}){

    const [ProfilePicture,setProfilePicture]=useState(defaultPicture)
    const [friendLogin, setFriendLogin]=useState(chatRoom.login1 === login ? chatRoom.login2 : chatRoom.login1 )
    const [lastChat, setLastChat] = useState(chatRoom.lastChat.chat.content)

    useEffect(()=>{
        async function getPP(){
            return await getProfilPicture(friendLogin);
        } 
        getPP().then((pp) => {
            if(pp){
                setProfilePicture(pp)
            }
        })
    },[]);

   
    

    return (
        <button onClick={() => { accessChatRoom(chatRoom) }} id="ChatRoomAccess_main">
            <img src={ProfilePicture} id="ChatRoomAccess_picture" alt="Profile-picture" />
            <h4 id="ChatRoomAccess-h4" >{friendLogin}</h4>
            <span id="last-chat-content">{chatRoom.lastChat.chat.content.length > 35 ? chatRoom.lastChat.chat.content.slice(0,32) + '...' : chatRoom.lastChat.chat.content}</span>
            <span id="last-chat-date">{chatRoom.lastChat.chat.dateFormat}</span>
        </button>
    );
} 
export default ChatRoomAccess