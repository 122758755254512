import './Post.css'
import {pages,heart_like,heart_unlike,com, defaultPicture} from '../assets/resources'
import { useEffect, useState } from 'react';
import {getMessage, like, isLike, getListMessages} from '../Api/message.js';
import { getProfilPicture } from '../Api/user';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Loading from '../Loading/Loading';

function Post({login, message, changeCurrentPage}){
    
    const { promiseInProgress } = usePromiseTracker();

    const [messageState, setMessage] = useState(message); 
    const [isLikeState, setIsLike] = useState((message.like.indexOf(login)===-1 ? false : true))
    const [nbLike, setNbLike ] = useState(message.like.length)
    const [likeButton, setLikeButton] = useState(0)
    const [refreshMessage, setRefreshMessage] = useState(false)
    const [commentList, setCommentList] = useState([]); 
    const [nbCom, setNbCom ] = useState(commentList.length)
    const [enRepA, setEnRepA]= useState()
    const [ProfilePicture,setProfilePicture]=useState(defaultPicture)

    useEffect(()=>{
        async function getPP(){
            return await trackPromise(getProfilPicture(message.login));
        } 
        getPP().then((pp) => {
            if(pp){
                setProfilePicture(pp)
            }
        })
    },[]);

    useEffect(() => {
        async function messageGet(){
            return await getMessage({messageid : message.messageid, branch : null} )
        }

        messageGet().then((list) => {
            setMessage(list);
        })

        async function getCommentList(){
            const branch = message.messageid
            return await getListMessages({login : null,branch: branch})
        }

        getCommentList().then((list) => {
            setCommentList(list); 
            setNbCom(list.length)
        })

    }, [login, refreshMessage]);

    useEffect(() => {
        async function getEnRepA(){
            if(message.branch !== 'main'){
                return await getMessage({messageid:null, branch: message.branch})
            }
        }
        getEnRepA().then((list) => {
            setEnRepA(list)
        })
    }, [])    

    useEffect(() => {
        async function getIsLike(){
            return await isLike(message.messageid)
        }

        getIsLike().then((list) => {
            if(list.data){
                setIsLike(list.data.isLike)
                setNbLike(list.data.nbLike)
            }
        })
    }, [login, messageState.like]);

    useEffect(() => {
        async function liker(){
            if(likeButton!=0){
                return await like(messageState.messageid)
            }
            return; 
        } 
        liker().then((list) => {
            setRefreshMessage(!refreshMessage)
        })
    
    }, [likeButton]); 
    
    return (
        <div>{promiseInProgress ? <Loading/> : 
        <div id='post-main' >
            <header id="post-header">
                <button onClick={() => { changeCurrentPage({page:pages.PROFILE, login : message.login}) }} id="Profile-return">
                    <img src={ProfilePicture} id="publication-picture" alt="Profile-picture" />
                </button>
                {message.branch!=='main' && enRepA && <button className='enreponse' onClick={() => { changeCurrentPage({page:pages.PUBLICATION, message : enRepA})}}>en réponse à @{enRepA.login}</button>}
                <button onClick={() => { changeCurrentPage({page:pages.PUBLICATION, message : messageState}) }} className='post' id="publi-button">
                    <h3 className='login-publi'>@{message.login}</h3>
                    <span className='content-publi'>“ {message.content} ”</span>
                </button>
            </header>
        <div id='post-button'>
            <button className='like' onClick={() => {setLikeButton(likeButton+1)}}>
                {nbLike}
                {!isLikeState && <img id="heart" src={heart_unlike}></img>}
                {isLikeState && <img id="heart" src={heart_like}></img>}
            </button>
            <button className='like' onClick={() => {}}> 
                {nbCom}
                <img id="heart" src={com}></img>
            </button>
            <span className='date'>{messageState.dateFormat}</span>
        </div>
        </div>
    }</div>
    );
} 
export default Post