import './PasswordModifier.css'
import {chevron} from '../../assets/resources';
import { changePassword } from '../../Api/user';
import { useState } from 'react';

function PasswordModifier({closePasswordModifier}){
    const [password, setPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [error, setError] = useState()

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        if (/(?=(.*\d){4})(?=.{10,})/.test(newPassword)) {
          setErrorPassword('');
          event.target.classList.remove('error');
          event.target.classList.add('valid');
        } else if(newPassword.length<10){
          setErrorPassword('Le mot de passe doit contenir au moins 10 caractères');
          event.target.classList.remove('valid');
          event.target.classList.add('error');
        }else{
            setErrorPassword('Le mot de passe doit contenir au moins 4 chiffres');
              event.target.classList.remove('valid');
              event.target.classList.add('error');
        }
    };

    async function handleSubmit (event){
        event.preventDefault();
        const oldPassword=document.getElementById('oldPassword').value;
        if (errorPassword) {
            return;
        }
        const response=await changePassword(oldPassword,password);
        setError(response)
        if(response.status===200){closePasswordModifier()};
    }

    return (
        <div className='PasswordModifier_main'>
             <div className='PasswordModifier-header'>
                <button className ='PasswordModifier_exitButton' onClick={() => { closePasswordModifier() }} id='PasswordModifier_exitButton'>
                        <img id='PasswordModifier_cross' src={chevron} className='PasswordModifier_exit_cross'></img>
                </button>
            <h2 className='PasswordModifier-h2'>Modifier votre mot de passe</h2>
            </div>
            <form className='PasswordModifier_form' onSubmit={handleSubmit}>
                <input placeholder='Ancien mot de passe' type='password' id='oldPassword' className='PasswordModifier_input'></input>
                
                <input placeholder='Nouveau mot de passe' type='password' id='newPassword'
                    className={`PasswordModifier-input ${errorPassword ? 'error' : ''} ${!errorPassword && password ? 'valid' : ''}`}
                    value={password} onChange={handlePasswordChange}
                />
                {error && error.status!==200 && <div className="PasswordModifier-error-message">{error.data}</div>}
                {errorPassword && <div className="PasswordModifier-error-message">{errorPassword}</div>}
                <button type="submit" className={`PasswordModifier_button ${(errorPassword) ? 'invalid' : 'valid'}`}>Valider</button>
            </form>
        </div>
    );
}

export default PasswordModifier