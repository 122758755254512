import { formToJSON } from 'axios';
import {api} from './api'


const createUser=async (formData) => {
  try {
    const response = await api.put('/user/signUp', formData);
    return response;
  } catch (error) {
    console.error(error);
    return error.response
  }
}

const sendLogin=async (formData)=>{
  try {
    const response = await api.post('/user/login', formData)
    return response
  }
  catch (error) {
    console.error(error)
    return error.response
  }
}

const deconnexion=async ()=>{
  try {
    api.delete('/user/session')
  }
  catch (error) {
    console.error(error);
  }
}

const newFriend=async (formData)=>{
  try {
    const response = await api.put('/user/addFriend', formData)
    return response.data;
  }
  catch (error) {
    console.error(error);
  }
}

const supFriend=async (formData)=>{
  try {
    const response = await api.delete('/user/deletefriend/'+ formData)
    return response.data;
  }
  catch (error) {
    console.error(error);
  }
}

const getFollowerList = async (formData) => {
    try{
      const response = await api.get('/user/getFollowerList/'+formData)
      return response.data
    }catch(error){ 
      console.error(error)
      return false;
    }
}


const getFollowingList = async (formData) => {
  try{
    const response = await api.get('/user/getFollowingList/'+formData)
    return response.data
  }catch(error){ 
    console.error(error)
    return false;
  }
}

const isFriend = async (formData) => {
  try{
    const friendlogin = formData.login
    const response = await api.get('/user/isFriend/' + friendlogin)
    return response.data
  }catch(error){ console.error(error)}
}

async function getRandomUsers() {
    try {
      const response = await api.get('/user/getRandom/3');
      if (response.data) {
    return response.data;
      } else {
          console.error('Error getting random users 1:', response.statusText);
      }
    } catch (error) {
      console.error('Error getting random users:', error);
    }
}

async function putProfilPicture(picture){
  try{  
    const response = await api.post('/user/profilePicture', {"picture":picture});
    return response;
  }
  catch (error) {
    console.error(error);
  }

}

async function getProfilPicture(login) {
  try {
    const response = await api.get('/user/profilePicture/'+login);
    if (response.data) {
      return response.data;
    } else {
        console.error('Error getting profilePicture', response.statusText);
    }
  } catch (error) {
    console.error('Error getting profilePicture(2) :', error);
  }
} 

async function changePassword(oldPassword,newPassword){
  try{
    const response = await api.post('/user/changePassword/'+oldPassword+'/'+newPassword);
    return response;
  }
  catch (error) {
    console.error('Error changing password : ', error);
    return error.response; 
  }
}

async function changeLogin(login){
  try{
    const response = await api.post('/user/changeLogin/'+login);
    return response;
  }
  catch (error) {
    console.error('Error changing login : ', error);
    return error.response
  }
}

async function deleteUser(){
  try{
    const response = await api.delete('/user');
    console.log(response.data);
    return response;
  }
  catch (error) {
    console.error('Error deleting user : ', error);
  }
}

async function search(value){
  try{
    const response = await api.get('/search/'+value)
    return response
  }catch (error) {
    console.error('Error in search : ', error);
  }
}

export {createUser,sendLogin,deconnexion,newFriend,supFriend,getFollowerList,getFollowingList,isFriend,getRandomUsers,putProfilPicture, getProfilPicture, changePassword, changeLogin, deleteUser, search}